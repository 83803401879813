<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-pencil
        </v-icon>Edit Status
      </h2>
      <form>
        <v-container>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="8"
            >
              <v-card-text class="text-body-1 text-center">
                <p class="my-p-style">
                  Update Work Order Status
                </p>
                <br>
                <v-select
                  v-model="workOrderStatus"
                  :items="workOrderStatusList"
                  item-value="id"
                  item-text="name"
                  label="Update Status"
                  dense
                  outlined
                  single-line
                />
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="UpdateWorkOrderStatus()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-container>
      </form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'EditWorkOrderStatus',
    components: {
      'centre-spinner': spinner,
    },
    props: {
      status: {
        type: String,
        required: true,
      },
      workOrderId: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        workOrderStatus: '',
        loading: false,
        workOrderStatusList: [{ name: 'Completed', id: 'completed' }, { name: 'Pending', id: 'pending' }],
      };
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.workOrderStatus = this.status;
      this.loading = false;
    },
    methods: {
      async UpdateWorkOrderStatus () {
        this.loading = true;
        await this.$store.dispatch('workorders/updateWorkOrderStatus', {
          workOrderStatus: { status: this.workOrderStatus },
          workOrderId: this.workOrderId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Work order Status updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('success');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },

    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
</style>
